<template>
  <div class="edit-view display-in-center view full-height" v-loading.fullscreen="loading">
    <div class="step-label">
      {{ title }}
    </div>

    <div v-if="barcodes.length > 0"
      class="overflow-y-auto border-2 border-solid border-gray-300 rounded-lg p-4 mt-4 h-1/2">
      <div v-for="(bc, index) in displayBarcodes" :key="bc.id">
        <BarcodeLabel :label="bc.label || bc?.id || ''" :subLabel="bc.id" :type="bc.barcodeType"
          @label:click="onViewDetailItem(bc)" @subLabel:click="onViewDetailItem(bc)" :labelClickable="true"
          :subLabelClickable="true" :labelOnly="!bc.label || !bc?.id">
          <template v-slot:action-menu v-if="index !== 0">
            <IconDelete class="cursor-pointer" @click="onDeleteItem(bc.id)" />
          </template>
        </BarcodeLabel>
      </div>
    </div>

    <div class="barcode-number-block">
      <span>
        {{ $t('Number of Scanned Barcodes') }}
      </span>
      <span class="label-box" @click="onCheckScannedBarcode">
        {{ isBulkAddTracking ? barcodes.length + 1 : barcodes.length }}
      </span>
    </div>
    <div class="flex justify-around">
      <el-button round @click="rescan" class="rescan-btn">
        {{ $t('rescan') }}
      </el-button>
      <el-button circle :disabled="barcodes.length === 0" @click="submit()" type="primary">
        {{ $t('Done') }}
      </el-button>
    </div>
  </div>

  <div class="dialog-area">
    <el-dialog :lock-scroll="true" center v-model="barcodeDialogVisible" width="90%" top="0" :show-close="true"
      :destroy-on-close="false" :title="$t('barcode_content')">
      <div v-if="barcode">
        <div class="scanned-barcode-data">
          <BarcodeLabel :type="barcode.barcodeType" :label="mainLabel ? mainLabel : barcode?.id" :subLabel="barcode.id"
            :labelOnly="!mainLabel || !barcode?.id" />
          <br />
          <div class="scanned-barcode-data__key-value-item" v-for="(value, label) in dimension" :key="label">
            <div v-if="$te(toSnakeCase(label))">
              {{ `${$t(toSnakeCase(label))}: ` }}
            </div>
            <div v-else>{{ `${label}: ` }}</div>
            <div :class="['value', getDisplayValue(value) === MASKED_ANNOTATION ? 'masked-value' : '']">
              {{ `${getDisplayValue(value)}` }}
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button circle type="primary" @click="barcodeDialogVisible = false">
            {{ $t('OK') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import BarcodeLabel from '@/components/BarcodeLabel.vue'
import BarcodeKeyValueInfoMixin from '@/components/mixins/BarcodeKeyValueInfoMixin.vue'
import IconDelete from '@/components/svg/IconDelete.vue'
import { ADD_TRACKING, CLEAR_ERROR, FETCH_BARCODE, SAVE_SCANNED_BARCODES } from '@/store/actions'
import errorHandler from '@/utils/errorHandler'
import { addLabelToBarcode } from '@/utils/helpers'
import cloneDeep from 'lodash/cloneDeep'
import { MASKED_ANNOTATION } from 'smartbarcode-web-core/src/utils/constants'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IBarcode, IDisplayBarcodes, IProject, TError } from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
@Options({
  components: {
    BarcodeLabel,
    IconDelete,
  },
  name: 'ScannedBCList',
})
export default class ScannedBCList extends mixins(BarcodeKeyValueInfoMixin) {
  loading = false
  barcodes = [] as IBarcode[]
  barcodeDialogVisible = false
  barcode = {} as IBarcode
  extParams = {} as Record<string, unknown>
  isAllowToLeave = false

  get MASKED_ANNOTATION() {
    return MASKED_ANNOTATION
  }

  get isBulkAddTracking() {
    return this.$route.params.operation === ADD_TRACKING
  }

  async beforeRouteLeave(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    if (this.isAllowToLeave) {
      next()
      return
    }
    await this.$confirm(this.$t('not_save_confirm'), '', {
      confirmButtonText: this.$t('ok'),
      confirmButtonClass: 'danger',
      cancelButtonText: this.$t('cancel'),
    })
      .then(async () => {
        next()
      })
      .catch((e: TError) => {
        if (e !== 'cancel') {
          next()
        }
      })
    next(false)
  }

  async created() {
    try {
      if (!this.$route.params.operation) {
        const parentBarcodeId = this.$store.state?.barcode?.barcode?.id
        if (!parentBarcodeId) {
          this.$router.replace({ name: 'home' })
          return
        }
        this.$router.replace({
          name: 'detail',
          params: {
            project: this.$store.getters.projectParam,
            barcodeId: parentBarcodeId,
          },
        })
      }
      this.loading = this.displayBarcodes.length > 0
      this.getBarcodeInfo()
      this.extParams = this.$route.params.extParams ? JSON.parse(this.$route.params.extParams) : {}

      this.barcodes = [...this.$store.state.barcode.scannedBCPool]
      if (isEmpty(this.barcodes)) this.rescan()
    } catch (error) {
      errorHandler(error as TError)
    } finally {
      this.loading = false
    }
  }

  get displayBarcodes() {
    const barcodes = cloneDeep(this.barcodes) as IDisplayBarcodes[]
    const currentBC = cloneDeep(this.$store.state.barcode.barcode)
    barcodes.unshift(currentBC)
    addLabelToBarcode(barcodes, this.project)
    return barcodes
  }

  // Reload bc in case user refresh browser
  async getBarcodeInfo() {
    const currentBC = this.$store.state.barcode.barcode
    const urlBCId = this.$route.params?.barcodeId

    if (!currentBC && urlBCId) {
      await this.$store.dispatch(FETCH_BARCODE, { id: this.$route.params?.barcodeId })
    }
  }

  get barcodeName() {
    return this.project?.barcodeTypes?.[this.barcodes[0]?.barcodeType]?.name || ''
  }

  get nextSelectedTrackingPoint(): string {
    return this.extParams?.selectedNextTrackpoint as string
  }

  get project(): IProject {
    return this.$store.state.project.details
  }

  get currentTrackPointKey() {
    return this.barcodes[0]?.currentTrackPointKey
  }

  get currentTrackPointName(): string {
    return this.project?.trackPoints[this.currentTrackPointKey]?.name || ''
  }

  get title(): string {
    return this.$route.params.title ?? this.$t('scanned_barcode_list')
  }

  onViewDetailItem(bc: IBarcode) {
    this.barcodeDialogVisible = true
    this.barcode = bc
  }

  onDeleteItem(bcId: string) {
    this.barcodes = this.barcodes.filter((bc) => bc.id !== bcId)
    this.$store.dispatch(SAVE_SCANNED_BARCODES, [...this.barcodes])
  }

  rescan() {
    this.isAllowToLeave = true
    this.$router.go(-1)
  }

  submit() {
    this.loading = true
    this.isAllowToLeave = true
    this.$store.dispatch(this.$route.params.operation, this.extParams)
  }

  get isAllowForcePairing() {
    return this.$store.state.project?.details?.allowForcePairing || false
  }

  @Watch('$store.state.barcode.error')
  operationFailed() {
    this.loading = false
    if (!this.$store.state.barcode.error) return

    const err = this.$store.state.barcode.errorMsg
    if (err === '3044' && this.isAllowForcePairing) {
      this.$confirm('', this.$t('confirm_overwrite_parent'), {
        confirmButtonText: this.$t('ok'),
        confirmButtonClass: 'danger',
        cancelButtonText: this.$t('cancel'),
      })
        .then(async () => {
          this.extParams.forcePairing = true
          this.forward()
        })
        .catch(() => true)
        .finally(() => this.$store.commit(CLEAR_ERROR))
      return
    }
    const modelObject: Record<string, string> = {
      barcodeType: this.barcodeName,
      fTP1: this.currentTrackPointName,
      tTP2: this.nextSelectedTrackingPoint,
    }

    errorHandler(this.$store.state.barcode.errorMsg, modelObject)
    this.$store.commit(CLEAR_ERROR)
  }

  @Watch('$store.state.barcode.actionCompleted')
  operationCompleted() {
    this.loading = false
    const parentBarcodeId = this.$store.state.barcode.barcode.id
    this.$router.replace({
      name: 'detail',
      params: {
        project: this.$store.getters.projectParam,
        barcodeId: parentBarcodeId,
      },
    })
  }
}
</script>
<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';

.view {
  max-width: 320px;
  margin: 0 auto;
  padding: 0 24px;
  padding-top: 66px;
  padding-bottom: 0px;
}

.masked-value {
  color: #ff0000;
}

.scanned-barcode-data {
  width: 100%;
  margin: 20px 10px 0px;

  &__id {
    color: #979797;
  }

  &__key-value-item {
    padding: 4px;
    display: flex;
    color: black;

    div:first-child {
      text-align: right;
      margin-right: 5px;
      flex-basis: 50%;
      min-width: 50%;
      word-break: break-all;
    }

    div:last-child {
      text-align: left;
    }
  }
}

.full-height {
  height: calc(100vh - 126px); // substract for padding
}

.dialog-area :deep() {
  .el-dialog__header {
    padding: 20px;
    text-align: left;
    background-color: $dark-grey;

    .el-dialog__title {
      color: $pure-white;
    }
  }

  .el-dialog__body {
    word-break: normal;
  }

  .el-dialog__headerbtn {
    background-color: $pure-white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }

  .barcode-label-container {
    display: flex;
    padding: 5px;
    margin: 0px;

    .action-menu {
      width: auto;
    }
  }
}

.rescan-btn {
  background-color: $border-color;
  color: $pure-white;

  &.is-round {
    height: 64px;
    border-radius: 40px !important;
    box-sizing: border-box !important;
  }
}

.barcode-label-container {
  background-color: $pure-white;
  border-color: $pure-white;
  padding: 16px 12px;
  margin: 10px 0px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 3px solid #edf2f7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}
</style>
