<template>
  <div class="edit-view display-in-center view" v-loading.fullscreen="loading || !isReadyToScan">
    <div class="step-label">
      {{ $t('bulk_skip_tracking') }}
    </div>
    <div class="barcode-scanner relative">
      <div class="scan-area" v-if="isScanditReaderProject" id="scan-area"></div>
      <div id="scan-area" class="scan-area relative" v-else>
        <video id="video"></video>
      </div>
      <BarcodeSymbologiesSelection class="absolute bottom-2 z-50 left-2 flex w-52"
        @update:onSymbologiesChange="setBarcodeSymbologies" v-model:symbologies="symbologies" />
      <ScanArea />
    </div>

    <div class="barcode-number-block">
      <span>
        {{ $t('Number of Scanned Barcodes') }}
      </span>
      <span class="label-box" @click="onCheckScannedBarcode">
        {{ scannedBarcodeList.length }}
        {{ bulkUpdateOperationLimitCount ? `/${bulkUpdateOperationLimitCount?.maxValue}` : '' }}
      </span>
    </div>
    <el-button circle type="primary" class="submit-button" :disabled="scannedBarcodeList.length < (bulkUpdateOperationLimitCount ? bulkUpdateOperationLimitCount?.minValue : 1)
    " @click="submit()">
      {{ $t('OK') }}
    </el-button>
  </div>
  <el-dialog center :lock-scroll="true" v-model="dialogVisible" width="90%" top="0" :show-close="false"
    :destroy-on-close="false" :title="checkScannedBarcode ? $t('Scanned Barcodes') : ''">
    <template v-if="checkScannedBarcode">
      <p v-if="scannedBarcodeList.length === 0">
        {{ $t('no barcodes') }}
      </p>
      <BarcodeLabelList v-else :barcodes="scannedBarcodeList || []" />
    </template>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="onCloseDialog">
          {{ $t('Done') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import ScanArea from '@/components/svg/ScanArea.vue'
import BarcodeLabelList from '@/components/BarcodeLabelList.vue'
import { ADD_TRACKING, FETCH_BARCODE, SAVE_SCANNED_BARCODES } from '@/store/actions'
import { IBarcode, IProject, ITrackingRequestBody } from 'smartbarcode-web-core/src/utils/types/index'
import MultipleBarcodeScanMixin from '@/components/mixins/MultipleBarcodeScanMixin.vue'
import { tracking } from '@/utils/api'
import BarcodeSymbologiesSelection from '@/components/BarcodeSymbologiesSelection.vue'
import { cloneDeep } from 'lodash'
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

@Options({
  components: {
    ScanArea,
    BarcodeLabelList,
    BarcodeSymbologiesSelection,
  },
  name: 'BulkTracking',
})
export default class BulkSkipBarcodeTracking extends mixins(MultipleBarcodeScanMixin) {
  loading = false
  dialogVisible = false
  checkScannedBarcode = false
  requestBody = {} as ITrackingRequestBody
  nextSelectTrackingPointName = ''
  isReadyToScan = true

  get barcode(): IBarcode {
    return this.$store.state.barcode.barcode
  }

  async created() {
    if (!this.$store.state.barcode.barcode) {
      await this.$store.dispatch(FETCH_BARCODE, {
        id: this.$route.params.barcodeId,
        trackingNumber: this.trackingNumberFromPath,
        externalId: this.externalIdFromPath,
        path: this.queryPath,
      })
    }
    this.nextSelectTrackingPointName = this.$route.params.nextTrackName
    this.requestBody = JSON.parse(localStorage.tempRequestForMultipleBarcodeScan)
    this.scannedBarcodeList.push(cloneDeep(this.barcode))
  }

  async beforeRouteLeave(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    await this.onLeaveGuard(to, from, next)
  }

  get bulkUpdateOperationLimitCount() {
    const project = this.$store.state.project.details as IProject
    const projectBarcode = project.barcodeTypes?.[this.$store.getters.barcodeType]
    return projectBarcode?.bulkUpdateOperationLimitCount
  }

  addBarcode(barcode: string) {
    ; (this.requestBody?.barcodeIds as string[]).push(barcode)
  }

  onCheckScannedBarcode() {
    this.checkScannedBarcode = true
    this.dialogVisible = true
  }

  onCloseDialog() {
    this.dialogVisible = false
    this.checkScannedBarcode = false
  }

  async validateBarcode(resultScanned: IBarcode) {
    const currentProjectId = this.$store.state.project.details.id
    const scannedBCProjectId = resultScanned.projectId
    if (currentProjectId !== scannedBCProjectId) throw this.$t('scanned_barcode_not_same_project')

    const { barcodeType } = resultScanned
    if (!barcodeType) throw this.$t('barcode not activated')

    const data = {
      barcodeId: this.barcode?.id,
      barcodeIds: [resultScanned?.id],
      trackingData: this.requestBody.trackingData,
      selectedNextTrackpoint: this.nextSelectTrackingPointName,
      isSkip: true,
      isDryRun: true,
    }

    await tracking(data)
  }

  forward() {
    this.$store.dispatch(SAVE_SCANNED_BARCODES, this.barcodes)
    this.$router.push({
      name: 'scanned-barcode-list',
      params: {
        title: this.$t('bulk_skip_tracking_read_result'),
        operation: ADD_TRACKING,
        extParams: JSON.stringify({
          trackingData: this.requestBody.trackingData,
          selectedNextTrackpoint: this.nextSelectTrackingPointName,
          isSkip: true,
        }),
      },
    })
  }
}
</script>

<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';

.scan-area {
  height: 100%;
}

.edit-view.view {
  padding-left: 0;
  padding-right: 0;
}

.step-label {
  margin-bottom: 25px;
}

.barcode-scanner {
  height: 390px;
  background: rgb(32, 32, 32);
  position: relative;
}

.barcode-scanner video {
  width: 100%;
  height: 100%;
}

.barcode-number-block {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
