
import { Options, mixins } from 'vue-class-component'
import ScanArea from '@/components/svg/ScanArea.vue'
import BarcodeLabelList from '@/components/BarcodeLabelList.vue'
import { ADD_TRACKING, FETCH_BARCODE, SAVE_SCANNED_BARCODES } from '@/store/actions'
import { IBarcode, IProject, ITrackingRequestBody } from 'smartbarcode-web-core/src/utils/types/index'
import MultipleBarcodeScanMixin from '@/components/mixins/MultipleBarcodeScanMixin.vue'
import { tracking } from '@/utils/api'
import BarcodeSymbologiesSelection from '@/components/BarcodeSymbologiesSelection.vue'
import { cloneDeep } from 'lodash'
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

@Options({
  components: {
    ScanArea,
    BarcodeLabelList,
    BarcodeSymbologiesSelection,
  },
  name: 'BulkTracking',
})
export default class BulkSkipBarcodeTracking extends mixins(MultipleBarcodeScanMixin) {
  loading = false
  dialogVisible = false
  checkScannedBarcode = false
  requestBody = {} as ITrackingRequestBody
  nextSelectTrackingPointName = ''
  isReadyToScan = true

  get barcode(): IBarcode {
    return this.$store.state.barcode.barcode
  }

  async created() {
    if (!this.$store.state.barcode.barcode) {
      await this.$store.dispatch(FETCH_BARCODE, {
        id: this.$route.params.barcodeId,
        trackingNumber: this.trackingNumberFromPath,
        externalId: this.externalIdFromPath,
        path: this.queryPath,
      })
    }
    this.nextSelectTrackingPointName = this.$route.params.nextTrackName
    this.requestBody = JSON.parse(localStorage.tempRequestForMultipleBarcodeScan)
    this.scannedBarcodeList.push(cloneDeep(this.barcode))
  }

  async beforeRouteLeave(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    await this.onLeaveGuard(to, from, next)
  }

  get bulkUpdateOperationLimitCount() {
    const project = this.$store.state.project.details as IProject
    const projectBarcode = project.barcodeTypes?.[this.$store.getters.barcodeType]
    return projectBarcode?.bulkUpdateOperationLimitCount
  }

  addBarcode(barcode: string) {
    ; (this.requestBody?.barcodeIds as string[]).push(barcode)
  }

  onCheckScannedBarcode() {
    this.checkScannedBarcode = true
    this.dialogVisible = true
  }

  onCloseDialog() {
    this.dialogVisible = false
    this.checkScannedBarcode = false
  }

  async validateBarcode(resultScanned: IBarcode) {
    const currentProjectId = this.$store.state.project.details.id
    const scannedBCProjectId = resultScanned.projectId
    if (currentProjectId !== scannedBCProjectId) throw this.$t('scanned_barcode_not_same_project')

    const { barcodeType } = resultScanned
    if (!barcodeType) throw this.$t('barcode not activated')

    const data = {
      barcodeId: this.barcode?.id,
      barcodeIds: [resultScanned?.id],
      trackingData: this.requestBody.trackingData,
      selectedNextTrackpoint: this.nextSelectTrackingPointName,
      isSkip: true,
      isDryRun: true,
    }

    await tracking(data)
  }

  forward() {
    this.$store.dispatch(SAVE_SCANNED_BARCODES, this.barcodes)
    this.$router.push({
      name: 'scanned-barcode-list',
      params: {
        title: this.$t('bulk_skip_tracking_read_result'),
        operation: ADD_TRACKING,
        extParams: JSON.stringify({
          trackingData: this.requestBody.trackingData,
          selectedNextTrackpoint: this.nextSelectTrackingPointName,
          isSkip: true,
        }),
      },
    })
  }
}
